<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <!--begin::Card-->
      <div class="card card-custom">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
          <div class="card-title">
            <h3 class="card-label">Liste des publicités</h3>
          </div>
          <div class="card-toolbar">
            <!--begin::Button-->
            <router-link
              :to="{ name: 'pub.new' }"
              class="btn btn-success font-weight-bolder"
            >
              <i class="la la-plus"></i> Ajouter
            </router-link>
            <!--end::Button-->
          </div>
        </div>
        <b-card-body>
          <!--begin: Datatable-->
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-select
                  style="width: 50px"
                  v-model="perPage"
                  :options="options"
                  size="sm"
                ></b-form-select>
              </b-col>
              <b-col md="6">
                <b-form-input
                  class="float-right mb-2"
                  style="width: 200px;"
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Rechercher"
                >
                </b-form-input>
              </b-col>
            </b-row>
          </b-form>
          <b-row>
            <b-col>
              <b-table
                bordered
                hover
                outlined
                :items="pubs"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                responsive="sm"
              >
                <template #cell(url)="data">
                  <img
                    class="img-responsive"
                    style="height:30px; width:40px"
                    :src="data.item.url"
                    alt="image publicite"
                  />
                </template>

                <template #cell(type_pub)="data">
                  <p class="">{{ data.value }}</p>
                </template>

                <template #cell(owner)="data">
                  <p class="">{{ data.value }}</p>
                </template>

                <template #cell(priority)="data">
                  <p class="">{{ data.value }}</p>
                </template>

                <template #cell(status)="data">
                  <h5>
                    <span class="badge badge-primary text-md-center ">{{
                      data.item
                    }}</span>
                  </h5>
                </template>

                <template v-slot:cell(actions)="data">
                  <router-link
                    class="btn btn-info btn-sm ml-2"
                    :to="{ name: 'pub.show', params: { id: data.item.id } }"
                  >
                    <i class="fas fa-eye"></i
                  ></router-link>

                  <router-link
                    class="btn btn-warning btn-sm ml-2"
                    :to="{ name: 'pub.edit', params: { id: data.item.id } }"
                  >
                    <i class="fas fa-edit"></i
                  ></router-link>

                  <button
                    v-show="user.roles[0]['name'] === 'ADM'"
                    class="btn btn-danger btn-sm ml-2"
                    @click="remove(data.item)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                :fields="fields"
                aria-controls="my-table"
                class="float-right mt-2"
              ></b-pagination>
            </b-col>
          </b-row>
          <!--end: Datatable-->
        </b-card-body>
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "PubList",
  data() {
    return {
      create: "publicite/create",
      source: "publicite",
      resource: "publicite",
      pubs: [],
      filter: "",
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "#"
        },
        {
          key: "priority",
          label: "Priorité"
        },
        {
          key: "url",
          label: "Image pub"
        },
        {
          key: "owner",
          label: "Auteur"
        },
        {
          key: "state",
          label: "Status"
        },
        "actions"
      ],
      options: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ]
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "publicite/" + this.$route.params.id + "/edit";
      this.store = "publicite/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.source).then(function(response) {
        vm.pubs = response.data.publicites;
      });
    },

    remove(pub) {
      let vm = this;
      Swal.fire({
        title:
          "Vous êtes sûr de vouloir supprimer la publicité " + pub.id + "?",
        text: "Il n'y aura aucun retour en arrière possible !",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      })
        .then(function(isConfirm) {
          if (isConfirm.isConfirmed) {
            ApiService.delete(`${vm.resource}/${pub.id}`)
              .then(response => {
                if (response.data.success) {
                  Swal.fire({
                    title: "Success",
                    text: response.data.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  });
                  vm.fetchData();
                } else {
                  Swal.fire({
                    title: "Ooups!",
                    text: "Erreur, impossible de supprimé la publicité.",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  });
                }
              })
              .catch(error => {
                Swal.fire({
                  title: "Ooups!",
                  text: error,
                  icon: "error",
                  showConfirmButton: false,
                  timer: 5000,
                  showClass: {
                    popup: "animate__animated animate__fadeInDown"
                  },
                  hideClass: {
                    popup: "animate__animated animate__fadeOutUp"
                  }
                });
              });
          } else {
            Swal.fire({
              title: "Ooups!",
              text: "Publicité non supprimée :)",
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {
    rows() {
      return this.pubs.length;
    },
    ...mapGetters({
      user: "user"
    })
  }
};
</script>

<style scoped></style>
